import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope, FaArrowRight } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
// import about from '../../../images/ab01.jpg';
import about from '../../../images/banner01.png';
import Arrow from './Arrow';
import { get } from '../../../utils/HttpService';
import api from '../config/api';

const Products = () => {
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [currentLiNum, setCurrentLiNum] = useState(0);
    const [currentCategoryName, setCategoryName] = useState('Door & Window');
    const [requestFlag, setRequestFlag] = useState(false);

    useEffect(() => {
        getProductCategory();
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    const getProductCategory = () => {
        get(api.api_product_productCategoryIndex, {
            params: {}
        }).then((res) => {
            if (res.code == '200') {
                if (res.data && res.data.length > 0) {
                    res.data = res.data.slice(0, 7); // 只取前7个
                    setProductCategory(res.data);
                    getProducts(res.data[0], 0);
                } else {
                    setRequestFlag(true)
                    setProductCategory([]);
                }
            }
        }).catch(error => {
        });
    }

    const getProducts = (item: any, currentLiNum: any) => {
        setCurrentLiNum(currentLiNum);
        setCategoryName(item.name);
        get(api.api_product_index, {
            params: {
                current: 1,
                pageSize: 20,
                categoryId: item.id ? item.id : 4,
                pageType: 'all',
            }
        }).then((res) => {
            setRequestFlag(true)
            if (res.code == '200') {
                if (res.data && res.data.length > 0) {
                    setServices(res.data);
                } else {
                    setServices([]);
                }
            }
        }).catch(error => {
        });
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // const productCategory = [
    //     {
    //         categoryId: 4,
    //         categoryName: "Door & Window",
    //     }, {
    //         categoryId: 13,
    //         categoryName: "Door Control",
    //     }, {
    //         categoryId: 29,
    //         categoryName: "Interior Products",
    //     }, {
    //         categoryId: 18,
    //         categoryName: "Facade",
    //     }, {
    //         categoryId: 22,
    //         categoryName: "Glass Railings",
    //     }, {
    //         categoryId: 1,
    //         categoryName: "Commercial Products",
    //     }, {
    //         categoryId: 43,
    //         categoryName: "Safety Equipment",
    //     }
    // ]
    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '75px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        >
                        </div>
                    </div>
                    <section>
                        <Container>
                            <div className='text-left'>
                                <p className='breadcrumb-text'>
                                    <span>Product</span>
                                    <span style={{ padding: '0 8px' }}>{">"}</span>
                                    <span style={{ fontWeight: '600' }}>{currentCategoryName}</span>
                                </p>
                                <p className='breadcrumb-title'>Products</p>

                                <div className='product-category-div'>
                                    {productCategory.map((item: any, index: any) => (
                                        <div className={currentLiNum == index ? 'product-category-div-item-active' : 'product-category-div-item'} key={'product-category-' + index} onClick={() => getProducts(item, index)}>
                                            <button>{item.name}</button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Row>
                                <Col md={12}>
                                    <div>
                                        <Row>
                                            {services.map((service: any, index: any) => (
                                                <Col sm={12} style={{ width: '20%' }} key={'services_' + index}>
                                                    <Card className="products-img-name-card">
                                                        <div className='products-img-name-card-img'>
                                                            <a href={`https://imart.kinlong.in/productdetails/${service.id}`} target="_blank">
                                                                <img src={service.cover_path} alt={service.product_name} style={{ height: '12.2rem' }} />
                                                            </a>
                                                        </div>
                                                        <div className='products-img-name-card-name'>
                                                            <p>
                                                                <a style={{ color: '#292D32' }} href={`https://imart.kinlong.in/productdetails/${service.id}`} target="_blank">{service.product_name}</a>
                                                            </p>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            {!requestFlag && <div style={{ height: '300px' }}></div>}
                        </Container>
                    </section>



                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990182', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>
                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/in/kinlong-india-766b98198" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div> */}
                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )}
        </div>
    );
};

export default Products;
