import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card, Modal, Button } from 'react-bootstrap';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter } from 'react-icons/fa';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import pop from '../../../images/ani01.png';
import Right from '../../../images/rightvg01.png';
import model from '../../../images/pop01.png';
import INTPRO from '../../../images/intpor01.png';
import DOORCONT from '../../../images/doorcont01.png';
import INTERPRODCT from '../../../images/interprdct01.png';
import FACADAIN from '../../../images/facadein01.png';
import PAPULAR from '../../../images/poplar01.png';
import PDR from '../../../images/dr011.png';
import RMP from '../../../images/railimar01.png';
import SPR from '../../../images/spidermp01.png';
import GMR from '../../../images/grmp01.png';
import LMR from '../../../images/lmp01.png';
import RSP from '../../../images/rsp01.png';
import WDH from '../../../images/wdh01.png';
import SDH from '../../../images/sdh01.png';
import whatsappIcon from '../../../images/whatspp.png';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import twittericon from '../../../images/twitter.png';
import emailicon from '../../../images/email.png';
import LANPRO from '../../../images/prone01.png';
import COMEPRO from '../../../images/comerpro01.png';
import RESPRO from '../../../images/respo01.png';
import aboutVideo from '../../../video/Kinlong.mp4';
import bn1 from '../../../images/bn01.png';
import bn3 from '../../../images/bn03.png';
import bn5 from '../../../images/bn05.png';
import bn6 from '../../../images/bn06.png';
import bn7 from '../../../images/bn07.png';
import bn10 from '../../../images/bn10.png';
import bn11 from '../../../images/bn11.png';
import bn13 from '../../../images/bn13.png';
import bn14 from '../../../images/bn14.png';
import bn15 from '../../../images/bn15.png';
import bn16 from '../../../images/bn16.png';
import bn17 from '../../../images/bn17.png';
import bn18 from '../../../images/bn18.png';
import bn20 from '../../../images/bn20.png';
import land from '../../../images/land01.png';
import door from '../../../images/door03.png';
import multi from '../../../images/multi01.jpg';
import Res from '../../../images/res01.png';
import doorHover from '../../../images/door04.png';
import doorcontrol from '../../../images/doorcontrol01.png';
import doorcontroll from '../../../images/doorcontrol02.png';
import interior from '../../../images/int01.png';
import interior1 from '../../../images/int02.png';
import facade from '../../../images/facade02.png';
import facade1 from '../../../images/facade03.png';
import about from '../../../images/abo01.png';
import Arrow from './Arrow';
import teambg from '../../../images/teambg01.png';
import teamMember1 from '../../../images/ceo01.png';
import teamMember2 from '../../../images/gm01.png';
import teamMember3 from '../../../images/director01.png';
import teamMember4 from '../../../images/dgm01.png';
import teamMember5 from '../../../images/dgm02.png';
import teamMember6 from '../../../images/dgm04.png';
import teamMember7 from '../../../images/dgm05.png';
import teamMember8 from '../../../images/dgm06.png';
import teamMember9 from '../../../images/hrm01.png';
import teamMember10 from '../../../images/financial01.png';
import teamMember11 from '../../../images/omd01.png';
import teamMember12 from '../../../images/designm01.png';
import teamMember13 from '../../../images/dgm03.png';


const Home = () => {
    const [loading, setLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [zoomClass, setZoomClass] = useState('zoom-in');
    const [showCookieBanner, setShowCookieBanner] = useState(false);
    const products = [
        {
            title: 'Door & Window',
            images: [door, doorHover],
        },
        {
            title: 'Door Control',
            images: [doorcontrol, doorcontroll],
        },
        {
            title: 'Interior Products',
            images: [interior, interior1],
        },
        {
            title: 'Facade',
            images: [facade, facade1],
        },
    ];
    const cards = [
        { title: "Landmark Projects", img: LANPRO },
        { title: "Commercial Projects", img: COMEPRO },
        { title: "Residential Projects", img: RESPRO },
    ];
    useEffect(() => {
        setShowModal(true);

        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);



    const handleCloseModal = () => {
        setShowModal(false);
        localStorage.setItem('hasSeenModal', 'true');
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const handleMouseEnter = (index: number) => {
        setActiveImageIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveImageIndex(null);
    };

    useEffect(() => {
        const cookieConsent = Cookies.get('cookieConsent');
        if (!cookieConsent) {
            setShowCookieBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        Cookies.set('cookieConsent', 'accepted', { expires: 365 });
        setShowCookieBanner(false);
    };

    const rejectCookies = () => {
        Cookies.set('cookieConsent', 'rejected', { expires: 365 });
        setShowCookieBanner(false);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };



    const teamMembers = [
        { id: 1, name: "Allen Chen", designation: "Vice President", img: teamMember1 },
        { id: 2, name: "Neil Wang", designation: "General Manager", img: teamMember2 },
        { id: 3, name: "Praveen Devadiga", designation: "Director & Head HR", img: teamMember3 },
        { id: 4, name: "Tony J Perayil", designation: "DGM Sales", img: teamMember4 },
        { id: 5, name: "Harshpinder Mann", designation: "DGM Sales", img: teamMember5 },
        { id: 6, name: "Prashant Gautam", designation: "DGM Sales", img: teamMember6 },
        { id: 7, name: "Akshay P Rahate", designation: "DGM Sales", img: teamMember13 },
        { id: 8, name: "Vijay Marshal", designation: "DGM Sales", img: teamMember7 },
        { id: 9, name: "Satyapal Singh", designation: "DGM Sales", img: teamMember8 },
        { id: 10, name: "Saugandh Radhakrishan", designation: "HR Manager & Head of Admin", img: teamMember9 },
        { id: 11, name: "Sathish Koleri", designation: "Finance Head", img: teamMember10 },
        { id: 12, name: "Somasundram E", designation: "SCM Head", img: teamMember11 },
        { id: 13, name: "Pradeep S M", designation: "Design Head", img: teamMember12 },
    ];

    const chunkTeamMembers = (members: typeof teamMembers, size: number) => {
        const chunks = [];
        for (let i = 0; i < members.length; i += size) {
            chunks.push(members.slice(i, i + size));
        }
        return chunks;
    };

    const teamChunks = chunkTeamMembers(teamMembers, 4);

    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    <section
                        style={{
                            backgroundImage: `url(${require('../../../images/banner01.png')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            padding: '149px 89px 267px 89px',
                            borderBottomLeftRadius: '49px',
                            borderBottomRightRadius: '49px',
                            overflow: 'hidden',
                        }}
                    >
                        <div className="home-section">
                            <Container>
                                <Row>
                                    <Col md={12} className="d-flex align-items-center">
                                        <div>
                                            <h4 className="bannertext">
                                                Wherever there is <span style={{ color: '#0179D4' }}><b>CONSTRUCTION</b></span>
                                            </h4>
                                            <h4 className="bannerrtext mt-2">THERE ARE KINLONG PRODUCTS</h4>
                                            <div className="d-flex align-items-center mt-3">
                                                <Nav.Link as={Link} to="/about">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Button
                                                            className="explore-btn"
                                                            style={{
                                                                backgroundColor: isHovered ? '#b23131' : '#FA4A4A',
                                                                color: '#fff',
                                                                fontFamily: 'Montserrat, sans-serif',
                                                                fontSize: '16px',
                                                                padding: '5px 52px',
                                                                border: 'none',
                                                                borderRadius: '23px',
                                                                transition: 'background-color 0.3s ease',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onMouseEnter={() => setIsHovered(true)}
                                                            onMouseLeave={() => setIsHovered(false)}
                                                        >
                                                            Explore
                                                            <img
                                                                src={Right}
                                                                alt="Right"
                                                                className="right-image"
                                                            />
                                                        </Button>
                                                    </div>
                                                </Nav.Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>
                    </section>

                    <section className='bannercards' style={{ padding: '30px 0' }}>
                        <Container>
                            <Row className="gy-3">
                                <Col md={3}>
                                    <a href="https://imart.kinlong.in/category/279" target="_blank">
                                        <Card
                                            style={{
                                                border: 'none',
                                                borderRadius: '15px',
                                                background: 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)',
                                                boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                height: '179px',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(28, 136, 214, 1) 30%, rgba(0, 66, 114, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                            }}
                                        >
                                            <Card.Body>
                                                <Card.Img
                                                    src={INTPRO}
                                                    style={{
                                                        display: 'block',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '20px',
                                                        paddingTop: '29px',
                                                        paddingBottom: '13px',
                                                        paddingRight: '20px',
                                                        width: '119px',
                                                        height: '119px',
                                                        maxWidth: '100%',
                                                    }}
                                                />
                                                <Card.Title className="mt-1">Door & Window</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>

                                </Col>

                                {/* Card 2 */}
                                <Col md={3}>
                                    <a href="https://imart.kinlong.in/category/289" target="_blank">
                                        <Card
                                            style={{
                                                border: 'none',
                                                borderRadius: '15px',
                                                background: 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)',
                                                boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                height: '179px',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(28, 136, 214, 1) 30%, rgba(0, 66, 114, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                            }}
                                        >
                                            <Card.Body>
                                                <Card.Img
                                                    src={DOORCONT}
                                                    style={{
                                                        display: 'block',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '20px',
                                                        paddingTop: '29px',
                                                        paddingBottom: '13px',
                                                        paddingRight: '20px',
                                                        width: '119px',
                                                        height: '119px',
                                                        maxWidth: '100%',
                                                    }}
                                                />
                                                <Card.Title className="mt-1">Door Controls</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Col>

                                {/* Card 3 */}
                                <Col md={3}>
                                    <a href="https://imart.kinlong.in/category/293" target="_blank">
                                        <Card
                                            style={{
                                                border: 'none',
                                                borderRadius: '15px',
                                                background: 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)',
                                                boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                height: '179px',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(28, 136, 214, 1) 30%, rgba(0, 66, 114, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                            }}
                                        >
                                            <Card.Body>
                                                <Card.Img
                                                    src={INTERPRODCT}
                                                    style={{
                                                        display: 'block',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '20px',
                                                        paddingTop: '29px',
                                                        paddingBottom: '13px',
                                                        paddingRight: '20px',
                                                        width: '119px',
                                                        height: '119px',
                                                        maxWidth: '100%',
                                                    }}
                                                />
                                                <Card.Title className="mt-1">Interior Products</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Col>

                                {/* Card 4 */}
                                <Col md={3}>
                                    <a href="https://imart.kinlong.in/category/304" target="_blank">
                                        <Card
                                            style={{
                                                border: 'none',
                                                borderRadius: '15px',
                                                background: 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)',
                                                boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                height: '179px',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(28, 136, 214, 1) 30%, rgba(0, 66, 114, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.background = 'radial-gradient(circle, rgba(6, 189, 255, 1) 30%, rgba(0, 97, 166, 1) 100%)';
                                                e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                            }}
                                        >
                                            <Card.Body>
                                                <Card.Img
                                                    src={FACADAIN}
                                                    style={{
                                                        display: 'block',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        paddingLeft: '20px',
                                                        paddingTop: '29px',
                                                        paddingBottom: '13px',
                                                        paddingRight: '20px',
                                                        width: '119px',
                                                        height: '119px',
                                                        maxWidth: '100%',
                                                    }}
                                                />
                                                <Card.Title className="mt-1">Facade</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className='aboutus'>
                        <Container className='mt-3'>
                            <Row>
                                <Col md={6} className="d-flex mb-5">
                                    <div className="text-start">
                                        <h2>Who we are</h2>
                                        <p className='aboutHome'>Kinlong Hardware (India) Private Limited is involved in the sales of Construction hardware materials in India and is known as one of the leading construction Hardware Expert in the world. Kinlong is engaged in construction hardware product development, manufacturing and sales to professional companies, our business is to provide high-quality architectural hardware products and related technical advice. Kinlong is the industry leading Manufacturer firm that commenced its business operations in India by the year 2014 to bring forth the best quality Hardware Fittings... </p>

                                        <Link to="/about" className="aboutbtn mt-5">
                                            About Us <FontAwesomeIcon icon={faArrowRight} />
                                        </Link>
                                    </div>
                                </Col>

                                <Col md={6} className="mb-5">
                                    <video
                                        src={aboutVideo}
                                        autoPlay
                                        loop
                                        muted
                                        style={{ width: '100%', borderRadius: '10px' }}
                                    />
                                    {/* <img src={about} alt="Right Side" style={{ width: '100%', borderRadius: '10px' }} /> */}
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    {showCookieBanner && (
                        <div className="cookie-banner">
                            <p>We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.</p>
                            <button onClick={acceptCookies} className="accept-button">Accept All Cookies</button>
                            <button onClick={rejectCookies} className="reject-button">Reject Cookies</button>
                        </div>
                    )}

                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>

                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}

                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>

                    <section className='brands'>
                        <Container className='mb-0'>
                            <h2 className='mb-4'>Our Brands</h2>
                            <Carousel>
                                <Carousel.Item>
                                    <Row>
                                        <Col>
                                            <img src={bn1} alt="Flex" />
                                        </Col>
                                        <Col>
                                            <img src={bn5} alt="GTI" />
                                        </Col>
                                        <Col>
                                            <img src={bn6} alt="GTI" />
                                        </Col>
                                        <Col>
                                            <img src={bn3} alt="Gilen" />
                                        </Col>

                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row>
                                        <Col>
                                            <img src={bn11} alt="Gilen" />
                                        </Col>
                                        <Col>
                                            <img src={bn7} alt="Flex" />
                                        </Col>
                                        <Col>
                                            <img src={bn10} alt="Gilen" />
                                        </Col>
                                        <Col>
                                            <img src={bn18} alt="Gilen" />
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row>
                                        <Col>
                                            <img src={bn15} alt="Gilen" />
                                        </Col>

                                        <Col>
                                            <img src={bn14} alt="Xingo" />
                                        </Col>
                                        <Col>
                                            <img src={bn13} alt="Xingo" />
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            </Carousel>
                        </Container>
                    </section>


                    <section className="projectsection mt-5"
                        style={{
                            backgroundImage: `url(${require('../../../images/proind01.png')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            padding: '33px 3px 75px 0px',
                            overflow: 'hidden',
                        }}
                    >
                        <Container>
                            <h3 className="projectheadtext mt-5">Our Excellent Projects</h3>
                            <p className="projectparatext">We have many quality projects in India. Kinlong focuses on providing supply of integrated construction</p>
                            <p className="projectparatext"> and building accessories and diversified products.</p>

                            <Row className="mt-5">
                                {cards.map((card, index) => (
                                    <Col md={4} key={index}>
                                        <Card className="hover-card">
                                            {card.title === "Landmark Projects" && (
                                                <Link to="/landmarkprojects">
                                                    <div className="image-wrapper">
                                                        <Card.Img src={card.img} alt={card.title} className="card-image" />
                                                        <div className="overlay"></div>
                                                        <div className="overlay-text">
                                                            <span className="explore-more">Explore More</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                            {card.title === "Commercial Projects" && (
                                                <Link to="/commercialprojects">
                                                    <div className="image-wrapper">
                                                        <Card.Img src={card.img} alt={card.title} className="card-image" />
                                                        <div className="overlay"></div>
                                                        <div className="overlay-text">
                                                            <span className="explore-more">Explore More</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                            {card.title === "Residential Projects" && (
                                                <Link to="/residentialprojects">
                                                    <div className="image-wrapper">
                                                        <Card.Img src={card.img} alt={card.title} className="card-image" />
                                                        <div className="overlay"></div>
                                                        <div className="overlay-text">
                                                            <span className="explore-more">Explore More</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )}
                                            <Card.Body className="card-body">
                                                <Card.Title className="card-titlee">{card.title}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>

                        </Container>

                    </section>
                    {/* 
                    <section className="products">
                        <Container className="mt-5">
                            <h2>Products <span style={{ color: '#2671ae' }}>Served</span></h2>
                            <Row className="mt-5">
                                {products.map((product, index) => (
                                    <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-4">
                                        {product.title === 'Door & Window' ? (
                                            <a
                                                href="https://imart.kinlong.in/#/category/8"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Card
                                                    className={`card-0${index + 1}`}
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <img
                                                        className="d-block img-fluid product-image"
                                                        src={activeImageIndex === index ? product.images[1] : product.images[0]}
                                                        alt={`Image ${index + 1}`}
                                                    />
                                                    <div className="card-bodyy">
                                                        <h4 className="card-title">{product.title}</h4>
                                                    </div>
                                                </Card>
                                            </a>
                                        ) : (
                                            <Link
                                                to={
                                                    product.title === 'Door Control' ? 'https://imart.kinlong.in/#/category/13' :
                                                        product.title === 'Interior Products' ? 'https://imart.kinlong.in/#/category/29' :
                                                            product.title === 'Facade' ? 'https://imart.kinlong.in/#/category/18' :
                                                                '/facade' 
                                                }
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                target={product.title === 'Door Control' || product.title === 'Interior Products' || product.title === 'Facade' ? "_blank" : "_self"}
                                                rel="noopener noreferrer"
                                            >
                                                <Card
                                                    className={`card-0${index + 1}`}
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onMouseLeave={handleMouseLeave}
                                                >
                                                    <img
                                                        className="d-block img-fluid product-image"
                                                        src={activeImageIndex === index ? product.images[1] : product.images[0]}
                                                        alt={`Image ${index + 1}`}
                                                    />
                                                    <div className="card-bodyy">
                                                        <h4 className="card-title">{product.title}</h4>
                                                    </div>
                                                </Card>
                                            </Link>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </section> */}

                    {/* Our Team Section */}
                    {/* <section className='team'>
                        <Container className='mt-5 mb-5'>
                            <h2>Our Tea<span style={{ color: '#2671ae' }}>m</span></h2>
                            <img src={teambg} alt="team" style={{ height: '49px', }} />

                            <Row className='mt-5'>
                                {teamMembers.map(member => (
                                    <Col md={3} key={member.id} className="mb-4 text-center">
                                        <img src={member.img} alt={member.name} className="rounded-circle" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                                        <h5>{member.name}</h5>
                                        <p style={{ fontStyle: 'italic', color: '#555' }}>{member.designation}</p>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </section> */}

                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="Twitter" width="60" />
                        </a>
                    </div> */}

                    <section className="team-section py-5 mt-2">
                        <Container>
                            <h2 className="text-center mb-4">Our Team</h2>
                            <p className="text-center mb-5">“Leading with Vision, Inspiring Excellence”</p>
                            <Carousel interval={5000} indicators={false} controls>
                                {teamChunks.map((chunk, index) => (
                                    <Carousel.Item key={index}>
                                        <Row className="justify-content-center">
                                            {chunk.map((member) => (
                                                <Col key={member.id} md={3}>
                                                    <Card className="text-center team-card">
                                                        <Card.Img
                                                            variant="top"
                                                            src={member.img}
                                                            alt={member.name}
                                                            className="team-img"
                                                        />
                                                        <Card.Body className="body-name">
                                                            <h6 style={{  
                                                                fontSize: '10px', 
                                                                position: 'relative',
                                                                color: '#ffffff'
                                                            }} className="membertitletext" title={member.name}>{member.name}</h6>
                                                            <Card.Text className="designationtext" title={member.designation}>{member.designation}</Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Container>
                    </section>

                    <section style={{ marginTop: '-20px' }}>
                        <Container>
                            <h2>Popular In Markets</h2>
                            <Row className="mt-4">
                                <Col md={3} className="text-right">
                                    <img src={PAPULAR} alt="team" style={{ width: '234px' }} />
                                </Col>
                                <Col md={9}>
                                    <Row className="g-3">
                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        position: 'relative',
                                                        right: '15px',
                                                        borderRadius: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/279" target="_blank" rel="noopener noreferrer">
                                                            <img src={PDR} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/279" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Door & Window Hardware</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/308" target="_blank" rel="noopener noreferrer">
                                                            <img src={RMP} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/308" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Railing  Systems</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/spider" target="_blank" rel="noopener noreferrer">
                                                            <img src={SPR} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/spider" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Spider And Clamp System</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/289" target="_blank" rel="noopener noreferrer">
                                                            <img src={GMR} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/289" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Glass Door Patch Fitting</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>


                                    <Row className="g-3 mt-2">
                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/303" target="_blank" rel="noopener noreferrer">
                                                            <img src={LMR} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/303" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Lock Hardware</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/309" target="_blank" rel="noopener noreferrer">
                                                            <img src={RSP} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/309" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Railing Shower Partition Hardware</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>


                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/294" target="_blank" rel="noopener noreferrer">
                                                            <img src={WDH} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/294" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara">Wooden Door Hardware</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <Row className="bordered-row w-100">
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <div className="popcard" style={{
                                                        border: 'none',
                                                        borderRadius: '15px',
                                                        position: 'relative',
                                                        right: '15px',
                                                        background: 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)', // Light to Dark Gradient
                                                        boxShadow: '4px 0px 5.3px rgba(0, 0, 0, 0.1)',
                                                        transition: 'background 0.3s ease, box-shadow 0.3s ease',
                                                    }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(28, 136, 214, 1) 0%, rgba(0, 66, 114, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.15)';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.background = 'linear-gradient(to right, rgba(6, 189, 255, 1) 0%, rgba(0, 97, 166, 1) 100%)';
                                                            e.currentTarget.style.boxShadow = '4px 0px 5.3px rgba(0, 0, 0, 0.1)';
                                                        }}>
                                                        <a href="https://imart.kinlong.in/category/294" target="_blank" rel="noopener noreferrer">
                                                            <img src={SDH} alt="Right Side" style={{ padding: '10px' }} />
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col md={6} className="d-flex justify-content-center align-items-center">
                                                    <a href="https://imart.kinlong.in/category/294" target="_blank" rel="noopener noreferrer">
                                                        <p className="markertpara" style={{ width: '123px' }}>Steel Door Hardware</p>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>


                    {/* 

     
                        <section className='market'>
                            <Container className='mt-5 mb-5'>
                                <h2>Popular In <span style={{ color: '#2671ae' }}>Markets</span></h2>
                                <Row className='mt-5'>
                                    <Col md={3} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/4" target="_blank" rel="noopener noreferrer">
                                                <p>Door & Window Hardware</p>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/22" target="_blank" rel="noopener noreferrer">
                                                <p>Railing Systems</p>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/19" target="_blank" rel="noopener noreferrer">
                                                <p>Spider And Clamp System</p>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/23" target="_blank" rel="noopener noreferrer">
                                                <p>Glass Door Patch Fitting</p>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/42" target="_blank" rel="noopener noreferrer">
                                                <p>Lock Hardware</p>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={4} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/32" target="_blank" rel="noopener noreferrer">
                                                <p>Shower Partition Hardware</p>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col md={4} className="mb-4 text-center">
                                        <div className='markettext'>
                                            <a href="https://imart.kinlong.in/#/category/31" target="_blank" rel="noopener noreferrer">
                                                <p>Wooden And Steel Door Hardware</p>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section> */}

                    <Footer />


                    {/* 
                    <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Body style={{ position: 'relative' }}>
                    <button
                        onClick={handleCloseModal}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            background: 'transparent',
                            border: 'none',
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                        aria-label="Close"
                    >
                        &times;
                    </button>
                    <img src={model} alt="Right Side" className="modal-img" style={{ width: '100%', height: 'auto' }} />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal> */}
                </>
            )}
        </div>
    );
};

export default Home;
