import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Button, Carousel, Card, Modal, Form, Alert } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import Care from '../../../images/carrerbg01.jpg';
import TICK from '../../../images/tick01.png';
import Exclude from '../../../images/Exclude.png';
import cardcarbg from '../../../images/cardcarbg.png';
import Workspaces from '../../../images/carop01.png';
import Recreation from '../../../images/recreation01.png';
import Mobile from '../../../images/mobile01.png';
import Fund from '../../../images/fund01.jpg';
import Game from '../../../images/game01.png';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
// import about from '../../../images/carrers01.jpg';
import about from '../../../images/banner01.png';
import productIcon from '../../../images/product.png';
import careersFile from '../../../images/careers-file.png';
import Arrow from './Arrow';
import { get } from '../../../utils/HttpService';
import api from '../config/api';

interface FormDataProps {
    name: string;
    position: string;
    experience: string;
    currentSalary: string;
    expectedSalary: string;
    noticePeriod: string;
    resume: File | null;
}
interface SuccessPopupProps {
    show: boolean;
    onHide: () => void;
}
const Careers = () => {
    const [loading, setLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [formData, setFormData] = useState<FormDataProps>({
        name: '',
        position: '',
        experience: '',
        currentSalary: '',
        expectedSalary: '',
        noticePeriod: '',
        resume: null,
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [submissionStatus, setSubmissionStatus] = useState<string>('');

    useEffect(() => {
        setShowModal(false);
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            console.log("File Selected:", file);
            const allowedExtensions = ['pdf', 'doc', 'docx'];
            const fileSizeLimit = 5 * 1024 * 1024; // 5MB

            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (!allowedExtensions.includes(fileExtension || '')) {
                setErrors(prev => ({ ...prev, resume: 'Invalid file type. Only PDF, DOC, DOCX allowed.' }));
                return;
            }

            if (file.size > fileSizeLimit) {
                setErrors(prev => ({ ...prev, resume: 'File size must not exceed 5MB.' }));
                return;
            }

            setFormData(prev => ({ ...prev, resume: file }));
            setErrors(prev => ({ ...prev, resume: '' })); // Clear errors
        }
    };



    const validateForm = () => {
        const errors: { [key: string]: string } = {};
        if (!formData.name) errors.name = 'Name is required';
        if (!formData.position) errors.position = 'Position is required';
        if (!formData.experience) errors.experience = 'Experience is required';
        if (!formData.currentSalary) errors.currentSalary = 'Current salary is required';
        if (!formData.expectedSalary) errors.expectedSalary = 'Expected salary is required';
        if (!formData.noticePeriod) errors.noticePeriod = 'Notice period is required';
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        if (!validateForm()) return;
    
        const formDataToSend = new FormData();
        formDataToSend.append('user_name', formData.name);
        formDataToSend.append('position_applied', formData.position);
        formDataToSend.append('user_experience', formData.experience);
        formDataToSend.append('current_salary', formData.currentSalary);
        formDataToSend.append('exp_salary', formData.expectedSalary);
        formDataToSend.append('notice_period', formData.noticePeriod);
    
        if (formData.resume) {
            formDataToSend.append('file', formData.resume, formData.resume.name);
        } else {
            setErrors(prev => ({ ...prev, resume: 'Resume file is required' }));
            return;
        }
    
        setLoading(true); 
    
        try {
            const response = await fetch(api.api_user_career, {
                method: 'POST',
                body: formDataToSend,
            });
    
            if (response.ok) {
                const responseData = await response.json();
                console.log('Success Response:', responseData);
    
                setShowModal(false);
                setShowSuccessPopup(true);
                setFormData({
                    name: '',
                    position: '',
                    experience: '',
                    currentSalary: '',
                    expectedSalary: '',
                    noticePeriod: '',
                    resume: null,
                });
                setErrors({});
            } else {
                const errorResponse = await response.json();
                console.error('Error Response:', errorResponse);
                setSubmissionStatus(`Failed to submit: ${errorResponse.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmissionStatus('An error occurred while submitting the application. Please try again later.');
        } finally {
            setLoading(false); 
        }
    };
    

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const SuccessPopup: React.FC<SuccessPopupProps> = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Submission Successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Your application has been submitted successfully!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <div className="boxed_wrapper ltr" style={{ backgroundColor: '#F7F7F7' }}>
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Header />
                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '3.8rem 0 9.5rem 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${Care})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>

                        {/* <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 2,
                            }}
                        ></div> */}

                        <Container style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb style={{ padding: '0.5rem' }}>
                                <h3 style={{ color: '#ffffff', fontWeight: 600, textDecoration: 'none', textAlign: 'end', fontSize: '2.2rem' }}>Join the Kinlong</h3>
                                <h5 style={{ color: '#ffffff', fontWeight: 500, textDecoration: 'none', textAlign: 'end', fontSize: '1.2rem' }}>SUPER HERO TEAM</h5>
                                <p style={{ color: '#ffffff', fontWeight: 500, textDecoration: 'none', textAlign: 'right', fontSize: '0.8rem', lineHeight: '1rem' }}>Please be aware that Kinlong will NEVER request any payment during the </p>
                                <p style={{ color: '#ffffff', fontWeight: 500, textDecoration: 'none', textAlign: 'right', fontSize: '0.8rem', lineHeight: '1rem' }}>recruitment process. Do not provide any financial details or make payments</p>
                                <p style={{ color: '#ffffff', fontWeight: 500, textDecoration: 'none', textAlign: 'right', fontSize: '0.8rem', lineHeight: '1rem' }}>to anyone claiming to represent Kinlong.</p>
                            </Breadcrumb>
                        </Container>
                    </div>

                    {/* Card Section */}
                    <section style={{ position: 'relative', zIndex: 4, marginTop: '-130px' }}>
                        <Container>
                            <Card style={{ background: '#ffffff', padding: '1.5rem 1.5rem 0 2.2rem ', borderRadius: '1rem' }}>
                                <Card.Body>
                                    <Row>
                                        <Col md={9}>
                                            <Row>
                                                <Col md={6} style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Competitive salary & performance-based incentives
                                                        </p>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Travel allowances for client meetings
                                                        </p>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Health insurance & employee benefits
                                                        </p>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Opportunities for career growth
                                                        </p>
                                                    </div>
                                                </Col>

                                                <Col md={6} style={{ textAlign: 'center', }}>
                                                    <div>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Training & development programs
                                                        </p>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Dynamic work environment & recognition
                                                        </p>
                                                        <p className="benefits">
                                                            <img src={TICK} alt="tick" /> Company-sponsored events & activities
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={3} style={{ marginTop: '-1.5rem' }}>
                                            <div style={{ backgroundImage: `url(${cardcarbg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%', height: '12rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
                                                <Button
                                                    onClick={handleShowModal}
                                                    style={{
                                                        backgroundColor: '#678FF3',
                                                        borderColor: '#678FF3',
                                                        color: '#ffffff',
                                                        borderRadius: '23px',
                                                        marginTop: '-2.2rem',
                                                        fontFamily: 'Montserrat',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Upload Resume
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Container>
                    </section>

                    {/* Job Postings Section */}
                    {/* <section className='about'>
                        <Container className='mt-5'>
                            <div>
                                <h2 style={{ fontWeight: '700' }}>Join the <span style={{ color: '#2671ae' }}>Kinlong</span></h2>
                                <h6>SUPER HERO TEAM</h6>
                                <p>Please be aware that Kinlong will NEVER request any payment during the recruitment process. Do not provide any financial details or make payments to anyone claiming to represent Kinlong. </p>
                            </div>
                        </Container>
                    </section> */}

                    <section className='about'>
                        <Container>
                            <div style={{ margin: '2.5rem 0' }}>
                                <h2 style={{ fontWeight: 700, fontSize: '2rem', fontFamily: 'Montserrat' }}>
                                    Current  Openings
                                </h2>
                            </div>
                            <Card style={{ width: '100%', backgroundColor: '#fff', borderRadius: '2rem' }}>
                                <Row>
                                    <Col md={8}>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '1.5em', width: '100%' }}>
                                            <Card.Body style={{ paddingLeft: '1em', textAlign: 'left' }}>
                                                <Card.Title style={{ display: 'flex', alignItems: 'center', }}>
                                                    <img src={productIcon} style={{ width: '1.6rem', height: 'auto' }} />
                                                    <span style={{
                                                        fontWeight: '700',
                                                        fontSize: '1.5em',
                                                        color: '#1B42A4',
                                                        fontFamily: 'Montserrat',
                                                        paddingLeft: '0.75rem',
                                                        paddingTop: '0.25rem',
                                                    }}>Sales Engineer</span>
                                                </Card.Title>
                                                <Card.Text style={{ fontSize: '0.9em', lineHeight: '1.25rem', marginTop: '1rem', }}>
                                                    <p style={{ textAlign: 'justify', fontFamily: 'Montserrat', color: '#333', }}>
                                                        <strong style={{ color: '#000000' }}>Role & Responsibilities:</strong> Aggressively seek new opportunities/leads in the Construction Industry and convert them into orders.
                                                        Maintain strong relationships with existing clients to ensure regular business.
                                                        Conduct meetings with Developers, Architects, Facade Consultants, Fabricators, and other stakeholders in the construction industry to promote our business.
                                                        Meet with Purchase Managers, Technical Design Heads, or Directors to gather information about ongoing projects.
                                                        Determine the business scope within projects related to Glass, Aluminum, UPVC, Steel, and Wood.
                                                        Provide necessary technical support and quotations to clients.
                                                        Negotiate with clients on prices, secure orders, and then place orders from Headquarters and the warehouse.
                                                        Follow up on orders and ensure timely payments.
                                                    </p>
                                                    <p style={{ marginTop: '1rem', fontFamily: 'Montserrat', color: '#333', }}>
                                                        <strong style={{ color: '#000000' }}>Preferred Candidate Profile:</strong> Sales experience in relevant field
                                                        Strong network & relationship-building skills
                                                        Ability to convert leads into orders
                                                        Excellent communication & negotiation skills
                                                        Familiarity with construction materials
                                                        Technical acumen & project understanding
                                                        Result-oriented with sales targets mindset
                                                    </p>
                                                    <p style={{ marginTop: '1rem', fontFamily: 'Montserrat', color: '#333', }}>
                                                        <strong style={{ color: '#000000' }}>Education:</strong> BE (Civil/Mechanical) preferred, other qualifications with relevant experience considered.
                                                    </p>
                                                    <p style={{ marginTop: '1rem', fontFamily: 'Montserrat', color: '#333', }}>
                                                        <strong style={{ color: '#000000' }}>Experience:</strong> 0-2 years
                                                    </p>
                                                    <p style={{ marginTop: '1rem', fontFamily: 'Montserrat', color: '#333', }}>
                                                        <strong style={{ color: '#000000' }}>Location:</strong> Chennai, Gurgoan, Bangalore
                                                    </p>
                                                </Card.Text>
                                            </Card.Body>
                                        </div>
                                    </Col>

                                    <Col md={4} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ paddingRight: '1rem', }}>
                                            <img
                                                src={Workspaces}
                                                alt="care"
                                                className="care"
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    objectFit: 'cover',
                                                    borderRadius: '1em',
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Container>
                    </section>




                    {/* <section>
                        <Container>
                            <Button onClick={handleShowModal}>Upload Resume</Button>
                        </Container>
                    </section> */}


                    <SuccessPopup show={showSuccessPopup} onHide={() => setShowSuccessPopup(false)} />

                    <Modal className='uploadResumeModal' show={showModal} onHide={handleCloseModal} centered size={"sm"}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <img src={careersFile} style={{ width: '1.2rem' }}></img>
                                <span style={{ fontFamily: 'Montserrat', fontSize: '1rem', fontWeight: '600', color: '#000000', paddingLeft: '0.5rem', }}>Upload Resume</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {submissionStatus && (
                                <Alert variant={submissionStatus.includes('successfully') ? 'success' : 'danger'}>
                                    {submissionStatus}
                                </Alert>
                            )}

                            <Form onSubmit={handleSubmit} encType="multipart/form-data">
                                <Form.Group controlId="formName">
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Name <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                placeholder="Enter your name"
                                            />
                                            {errors.name && <small className="text-danger">{errors.name}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formPosition" style={{ marginTop: '0.875rem' }}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Looking for <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="text"
                                                name="position"
                                                value={formData.position}
                                                onChange={handleInputChange}
                                                placeholder="Position you're looking for"
                                            />
                                            {errors.position && <small className="text-danger">{errors.position}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formExperience" style={{ marginTop: '0.875rem' }}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Experience <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="text"
                                                name="experience"
                                                value={formData.experience}
                                                onChange={handleInputChange}
                                                placeholder="Years of experience"
                                            />
                                            {errors.experience && <small className="text-danger">{errors.experience}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formCurrentSalary" style={{ marginTop: '0.875rem' }}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Current Salary <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="text"
                                                name="currentSalary"
                                                value={formData.currentSalary}
                                                onChange={handleInputChange}
                                                placeholder="Current salary"
                                            />
                                            {errors.currentSalary && <small className="text-danger">{errors.currentSalary}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formExpectedSalary" style={{ marginTop: '0.875rem' }}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Expected Salary <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="text"
                                                name="expectedSalary"
                                                value={formData.expectedSalary}
                                                onChange={handleInputChange}
                                                placeholder="Expected salary"
                                            />
                                            {errors.expectedSalary && <small className="text-danger">{errors.expectedSalary}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formNoticePeriod" style={{ marginTop: '0.875rem' }}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Notice Period <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="text"
                                                name="noticePeriod"
                                                value={formData.noticePeriod}
                                                onChange={handleInputChange}
                                                placeholder="Notice period in days"
                                            />
                                            {errors.noticePeriod && <small className="text-danger">{errors.noticePeriod}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group controlId="formFile" style={{ marginTop: '0.875rem' }}>
                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                        <Col md={3} style={{ textAlign: 'right' }}>
                                            <Form.Label style={{ fontFamily: 'Montserrat', fontSize: '0.875rem', fontWeight: '400', color: '#000000' }}>
                                                Upload Resume <span style={{ color: '#d80e0e' }}>*</span>
                                            </Form.Label>
                                        </Col>
                                        <Col md={9}>
                                            <Form.Control
                                                type="file"
                                                name="resume"
                                                onChange={handleFileChange}
                                            />
                                            {errors.resume && <small className="text-danger">{errors.resume}</small>}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <div style={{ textAlign: 'center', marginTop: '2.2rem', marginBottom: '0.3rem' }}>
                                    <Button style={{ width: '8.5rem', backgroundColor: '#E2E1E1', borderRadius: '0.25rem', border: 'none', padding: '0.5rem', marginRight: '2.5rem', }} type="button" variant="secondary" onClick={handleCloseModal}>
                                        <span style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '1rem', color: '#606060' }}>Close</span>
                                    </Button>

                                     <Button
                                        style={{ width: '8.5rem', backgroundColor: '#197CBA', borderRadius: '0.25rem', border: 'none', padding: '0.5rem' }}
                                        type="submit"
                                        variant="primary"
                                        disabled={loading} 
                                    >
                                        {loading ? (
                                            <span style={{color:'red'}}>Submitting...</span> 
                                        ) : (
                                            <span style={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '1rem', color: '#fff' }}>
                                                Submit
                                            </span>
                                        )}
                                    </Button>
                                </div>

                                {/* Footer Buttons */}
                                {/* <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Modal.Footer> */}
                            </Form>


                        </Modal.Body>
                    </Modal>

                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>

                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd/" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india/" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/company/kinlong-hardware-india-private-limited-official/?viewAsMember=true" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div> */}
                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )
            }
        </div >
    );
};

export default Careers;
