import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube, FaTwitter, FaPhone, FaEnvelope } from 'react-icons/fa';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../home/Header';
import Footer from '../home/Footer';
import { Document, Page, pdfjs } from 'react-pdf';
import '../styles/Styles.css';
import '../styles/Bootstrap.css';
import Doorcontrols from '../../../pdf/Doorcontrols.pdf';
import Steel from '../../../pdf/Steel.pdf';
import Smartwindow from '../../../pdf/Smartwindow.pdf';
import Anchorchannel from '../../../pdf/Anchorchannel.pdf';
import PointfixedGlassWall from '../../../pdf/PointfixedGlassWall.pdf';
import Postrailings from '../../../pdf/Postrailings.pdf';
import FurnitureHardware from '../../../pdf/FurnitureHardware.pdf';
import ShowerRoomPicture from '../../../pdf/ShowerRoomPicture.pdf';
import AluminiumDoor from '../../../pdf/AluminiumDoor.pdf';
import Bathroomhardware from '../../../pdf/Bathroomhardware.pdf';
import KinlongModular from '../../../pdf/KinlongModular.pdf';
import UGroove from '../../../pdf/UGroove.pdf';
import whatsappbussinesIcon from '../../../images/whastappb01.png';
import pdf1 from '../../../images/pdf01.jpg';
import pdf2 from '../../../images/pdf02.png';
import pdf3 from '../../../images/pdf03.png';
import pdf4 from '../../../images/pdf04.png';
import pdf5 from '../../../images/pdf05.png';
import pdf6 from '../../../images/pdf06.png';
import pdf7 from '../../../images/pdf07.png';
import pdf8 from '../../../images/pdf08.png';
import pdf9 from '../../../images/pdf09.png';
import pdf10 from '../../../images/pdf10.png';
import pdf11 from '../../../images/pdf11.png';
import pdf12 from '../../../images/pdf12.png';
import emailicon from '../../../images/email.png';
import twittericon from '../../../images/twitter.png';
import whatsappIcon from '../../../images/whatspp.png';
import about from '../../../images/banner01.png';
import Arrow from './Arrow';

pdfjs.GlobalWorkerOptions.workerSrc = `http://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.worker.js`;

const Catalogue = () => {
    const [loading, setLoading] = useState(false);
    const [numPages, setNumPages] = useState(0); // Initialize numPages as 0
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages); // Explicitly type numPages as number
    };

    const goToNextPage = () => setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
    const goToPrevPage = () => setPageNumber((prevPage) => Math.max(prevPage - 1, 1));


    return (
        <div className="boxed_wrapper ltr">
            {loading ? (
                <div className="loader-wrap">
                    <div className="preloader">
                        <div id="handle-preloader" className="handle-preloader">
                            <div className="animation-preloader">
                                <div className="spinner"></div>
                                <div className="txt-loading">
                                    {['K', 'I', 'N', 'L', 'O', 'N', 'G'].map((letter, index) => (
                                        <span key={index} data-text-preloader={letter} className="letters-loading">
                                            {letter}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <Header />

                    <div className="breadcrumb-section" style={{ position: 'relative', padding: '75px 0', textAlign: 'center' }}>
                        <div
                            style={{
                                backgroundImage: `url(${about})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1,
                            }}
                        ></div>
                        {/* <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgb(255 255 255 / 33%)',
                                zIndex: 2,
                            }}
                        ></div>
                        <Container fluid style={{ position: 'relative', zIndex: 3 }}>
                            <Breadcrumb className="breadcrumb mt-5">
                                <h1 style={{ color: '#ffffff', textDecoration: 'none', top: 29 }}>
                                    Contact <span style={{ color: '#2671ae' }}>Us</span>
                                </h1>
                            </Breadcrumb>
                        </Container> */}
                    </div>

                    <section>
                        <Container>
                            <div className='text-left mt-3'>
                                <p className='breadcrumb-text'>
                                    <span>Home</span>
                                    <span style={{ padding: '0 8px' }}>{">"}</span>
                                    <span style={{ fontWeight: '600' }}>Catalogue</span>
                                </p>
                                <p className='breadcrumb-title'>Catalogue</p>
                            </div>

                            <div className='mt-3' style={{ backgroundColor: '#f7f7f7', padding: '2rem 2.2rem', }}>
                                <Row className="justify-content-center">
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf3} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Door Controls</Card.Title>
                                                    <a className='samplePdf' href={Doorcontrols} download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf1} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Steel Wire Mesh</Card.Title>
                                                    <a className='samplePdf'
                                                        href={Steel}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf2} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Smart Window Control</Card.Title>
                                                    <a className='samplePdf'
                                                        href={Smartwindow}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf4} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Anchor Channel</Card.Title>
                                                    <a className='samplePdf'
                                                        href={Anchorchannel}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf5} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Point-Fixed Glass Wall</Card.Title>
                                                    <a className='samplePdf'
                                                        href={PointfixedGlassWall}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf6} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Post Railings</Card.Title>
                                                    <a className='samplePdf'
                                                        href={Postrailings}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf7} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Furniture Hardware</Card.Title>
                                                    <a className='samplePdf'
                                                        href={FurnitureHardware}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf8} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Shower Room Picture</Card.Title>
                                                    <a className='samplePdf'
                                                        href={ShowerRoomPicture}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf9} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Aluminium Door & Window</Card.Title>
                                                    <a className='samplePdf'
                                                        href={AluminiumDoor}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>

                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf10} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Bathroom Hardware</Card.Title>
                                                    <a className='samplePdf'
                                                        href={Bathroomhardware}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf11} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>Kinlong Modular Support System</Card.Title>
                                                    <a className='samplePdf'
                                                        href={KinlongModular}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>

                                    <Col md={3} className="mb-2 text-center">
                                        <div className="catalogue-box">
                                            <Card className="border-custom shadow-custom">
                                                <div className="catalogue-img">
                                                    <Card.Img variant="top" src={pdf12} alt="PDF Thumbnail" />
                                                </div>
                                                <Card.Body className="d-flex justify-content-between align-items-center mt-3" style={{ height: '3rem' }}>
                                                    <Card.Title className='catalogue-text'>U Groove Door&Window </Card.Title>
                                                    <a className='samplePdf'
                                                        href={UGroove}
                                                        download>
                                                        Download
                                                    </a>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Container>
                    </section>



                    {/* WhatsApp Icon */}
                    {/* <div className="whatsapp-icon" onClick={() => window.open('https://wa.me/+919108990181', '_blank')}>
                        <img src={whatsappIcon} alt="WhatsApp" width="60" />
                    </div>
                    <a href="mailto:inhr@kinlong.com" className="email-icon">
                        <img src={emailicon} alt="Email" width="60" />
                    </a> */}
                    <div
                        className="arrow-icon"
                        style={{
                            position: 'fixed',
                            bottom: '73px',
                            right: '20px',
                            cursor: 'pointer',
                            zIndex: 1000,
                        }}
                    >
                        <Arrow onClick={scrollToTop} />
                    </div>
                    {/* <div className="sticky-social">
                        <a href="https://www.facebook.com/KinlongHardwareIndiaPvtLtd" target="_blank" className="social-link">
                            <FaFacebookF className="icon" style={{ color: '#4267B2', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.instagram.com/kinlong_india" target="_blank" className="social-link">
                            <FaInstagram className="icon" style={{ color: '#E4405F', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.linkedin.com/in/kinlong-india-766b98198" target="_blank" className="social-link">
                            <FaLinkedinIn className="icon" style={{ color: '#0077B5', fontSize: '15px' }} />
                        </a>

                        <a href="https://www.youtube.com/@kinlonghardwareindiapvtltd5906" target="_blank" className="social-link">
                            <FaYoutube className="icon" style={{ color: '#FF0000', fontSize: '15px' }} />
                        </a>

                        <a href="https://twitter.com/GKinlongHQ" target="_blank" className="social-link">
                            <img src={twittericon} alt="Twitter" width="60" />
                        </a>
                        <a href="https://www.whatsapp.com/channel/0029Va75kTn5vKA3hzDBAN34" target="_blank" className="social-link">
                            <img src={whatsappbussinesIcon} alt="WhatsApp" width="60" />
                        </a>
                    </div> */}
                    {/* Footer Section */}
                    <section>
                        <Footer />
                    </section>
                </>
            )}
        </div>
    );
};

export default Catalogue;
